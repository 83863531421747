import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import CustomerCard from "./common/CustomerCard";
import CustomerTable from "./common/CustomerTable";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { BsFunnel, BsGrid, BsListUl } from "react-icons/bs";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

export default function AllAffiliatedCustomers() {
	const [dashboardData, setdDashboardData] = useState(null);
	const [dataType, setDataType] = useState("grid");
  const { loginUserData, affData } = useContext(CustomProvider);
  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");
  }


  




  useEffect(() => {
    setdDashboardData(affData);
    
  }, [affData]);


  const handleChange = (event) => {
    console.log(event.target.value);
  };
  return (
    <>
      <AffiliateLayout>
        <div
          className="sep-title-area">
          <div className="sep-title-area-left">
            <h2>All Customers</h2>
          </div>
          <div
            className="sep-title-area-right">
            <FormControl sx={{ width: "150px" }}>
              <legend className="sep-title-select">Sort By</legend>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl>
            <Button className="sep-filter-btn"
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button>
            <Button className="sep-viewlist-btn"
            onClick={()=>setDataType(dataType === "grid"?"list":"grid")}
              variant="contained"
              color="success"
            >
              {dataType === "grid" ? (
                <>
                  <BsListUl />
                  <span>List View</span>
                </>
              ) : (
                <>
                  <BsGrid />
                  <span>Grid View</span>
                </>
              )}
            </Button>
          </div>
        </div>
        {dataType === "grid" ? (
          <div
            className="data-grid-area sep-grid-table-cards">
            {dashboardData?.referralNewUserData?.referralUserData?.map((data) => {
              return <CustomerCard userData={data} dashboardData={dashboardData} />;
            })}
          </div>
        ) : (
          <div className="sep-trail-table" >
            <CustomerTable dashboardData={dashboardData} />
          </div>
        )}
      </AffiliateLayout>
    </>
  );
}
