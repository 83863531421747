import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import React, { useContext } from "react";

export default function CustomerCard(props) {
  const { loginUserData } = useContext(CustomProvider);
	const color = {
		"In trail" : "#2c73ff",
		"Active": "#22C55E",
		"Paid": "#22C55E",
		"To Be Collected": "#FFC804",
		"Payment Due": "#FF7A00",
		"Cancelled": "#FF0000"
	};

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const formatDate2 = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return "Invalid Date";
    }
  
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  

  const formatDate1 = (dateInput) => {
    let date;
    date = new Date(dateInput); // Handle ISO 8601 format
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        avatar={
          <Avatar
            alt="Avatar Image"
            src={
              props?.dashboardData?.referralNewUserData?.pictureUrl +
              props?.userData?.picture
            }
            sx={{ width: 44, height: 44 }}
          />
        }
        title={
          <Typography >
            {props?.userData?.firstname + " " + props?.userData?.lastname}
          </Typography>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <table>
          <tbody>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Email
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {props?.userData?.email}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Plan
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {props?.userData?.pkg_name}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Price
              </th>
              <td
                style={{
                  textAlign: "left",
                  
                }}
              >
                {props?.userData?.amount + " " + props?.userData?.currency}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Joining Date
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {props?.userData?.createdat && formatDate1(props?.userData?.createdat)}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Next Payment
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {props?.userData?.nextBillingAt
                  ? formatDate2(props?.userData?.nextBillingAt)
                  : "No Date Available"}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Sponsored by{" "}
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {loginUserData?.username}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  textAlign: "left",
                }}
              >
                Status
              </th>
              <td
                style={{
                  textAlign: "left",
                }}
              >
                {props?.userData?.trial_status === 'Active' ? (
											<span className="sep-gridview-tags"  style={{
												background: color['In trial'],
											}}>
												{
													'In trial'
												}
											</span>
										) : (
											<span  style={{
												background: color[props?.userData?.subscription_status],
											}}>
												{
													props?.userData?.subscription_status
												}
											</span>
										)}
              </td>
            </tr>

          </tbody>
        </table>
      </CardContent>
    </Card>
  );
}
