import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { stepCount } from "..";
import agmobileimg from "../images/ag-mobile-img.png";
import agpreviewuser from "../images/ag-preview-user.png";
import { Button } from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { useContext } from "react";

export function PreviewScreen({
  setStep,
  previewData,
  setNewMessageId,
  messageId,
  isCreateMessage,
  handleClose,
  setIsCreateMessage,
  isUpdate,
  closePreview,
  selectedData,
  handledEdit
}) {
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const { loginUserData: userData } = useContext(CustomProvider);
  let fbData = localStorage.getItem("facebookData") && JSON.parse(localStorage.getItem("facebookData"))
  let instData = localStorage.getItem("instagramData") && JSON.parse(localStorage.getItem("instagramData"))
  // let variants = [];
  // previewData.forEach((name) => {
  //   variants.push({name})
  // })

  console.log("previewData", previewData);

  return (
    <div class="ag-msg-popup">
      <div class="ag-msg-popup-inner ag-scroll">
        <div class="ag-msg-popup-content">
          <div class="ag-msg-step-03">
           
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <div class="ag-main-title">Preview messages</div>
            {selectedData && <div>
             <Button 
              onClick={() => handledEdit(selectedData)}
              sx={{
                background: "#E7E7E7",
                fontWeight: 500,
                border:"1px solid #ccc",
                borderRadius:"30px",
                "&:hover": {
                  backgroundColor: "white",
                  border:"1px solid #ccc",
                 
                },
              }}
     
              className="ag-lg-btn"
              variant="outlined"
            >
              <EditCalendarIcon sx={{ mr: "5px", height: "20px", color:"#747474" }} />
              <span style={{ color:"#747474"}}>Edit</span>
            </Button>
             </div>}
            </div>


            <div class="ag-msg-preview-main">
              <div class="ag-msg-preview">
                {previewData &&
                  previewData.variants.map((data) => {
                    return (
                      <div class="ag-msg-preview-items">
                        <img src={agmobileimg} alt="" />
                        <div class="ag-msg-preview-text">
                          <div class="ag-msg-preview-textInner">
                            <div class="ag-msg-preview-header">
                              <img
                                src={ 
                                  
                                  fbData?.profile_image || instData?.profile_image 
                                }
                                alt=""
                              />
                              <span class="ag-previwer-name">
                                {userData?.firstname} {userData?.lastname}
                                <span>Business chat</span>
                              </span>
                            </div>
                            <div class="ag-msg-preview-wraper">
                              <img
                                src={
                                  fbData?.profile_image || instData?.profile_image 
                                }
                                alt=""
                              />
                              <div class="ag-previwer-textwrap">
                                <span class="ag-msg-content" style={{whiteSpace:"pre-line"}}>{data.name||data.text}</span>
                                <span class="ag-msg-time">{currentTime}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div class="ag-btn-group">
              <button
                class="ag-lg-btn"
                onClick={() => {
                  if(isUpdate){
                    closePreview()
                  }else{
                         setStep(
                    isCreateMessage
                      ? stepCount.createScreen
                      : stepCount.listScreen
                  );
                  setIsCreateMessage(false);
                  }
             
                }}
              >
                Back
              </button>
              {!isUpdate && !isCreateMessage && (
                <button
                  class="ag-lg-btn ag-bg-gray"
                  onClick={() => {
                    setNewMessageId({...previewData});
                    // handleClose();
                  }}
                >
                  Select this Template
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 5.24242V6.75758H9.84848L5.3346 10.9242L6.5 12L13 6L6.5 0L5.3346 1.07576L9.84848 5.24242H0Z"
                      fill="#fff"
                    ></path>
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
