import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import './setting.css'
import { useTranslation } from "react-i18next";
import { BsUpload } from "react-icons/bs";
import { GoArrowRight } from "react-icons/go";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import * as yup from "yup";
import {
  UpdatePayoutDetailsApi,
  uploadKycData,
} from "backendServices/ApiCalls";
import { countries } from "../components/mui/AutoCompletes/data";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import CodeIcon from '@mui/icons-material/Code';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";

// let validationSchema = yup.object({});
// let initialValues = {};

export default function AffiliateSettings() {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  // const [documentType, setDocumentType] = useState("id_card");
  const [paymentInformationType, setPaymentInformationType] = useState("Bank");
  const [selectedValue, setSelectedValue] = useState("Identity Card");
  const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgressBack, setUploadProgressBack] = useState(0);
  const [isUploadingBack, setIsUploadingBack] = useState(false);
  const [passportImage, setPassportImage] = useState(null);
  const [uploadProgressPassport, setUploadProgressPassport] = useState(0);
  const [isUploadingPassport, setIsUploadingPassport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formDataErrors, setFormDataErrors] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [subload, setSubload] = useState(false);


  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");}

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [formValues, setFormValues] = useState({
    country: "",
    fullName: "",
    iban: "",
    bic: "",
    address: "",
    city: "",
    zipCode: "",
    pcountry: "",
  });

  const [formData, setFormData] = useState({
    country: "",
    bankAccountName: "",
    bankAccountNumber: "",
    bankAccountSwiftCode: "",
    bankAccountRouting: "",
    address: "",
    city: "",
    zipCode: "",
    pcountry: "",
  });



  // const handleSelectChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedValue(value);
  //   setImageBack(null);
  //   setImageFront(null);
  //   if (value === "Passport") {
  //     validationSchema = yup.object({
  //       identityType: yup.string().required("Indentity Type Fee is required"),
  //       idcardFront: yup.mixed().required("Passport Front is required"),
  //       residentialAddress: yup
  //         .string()
  //         .required("Residential Address is required"),
  //     });
  //     initialValues = {
  //       identityType: "Select Type",
  //       idcardFront: null,
  //       residentialAddress: "",
  //     };
  //   } else if (value === "Identity Card") {
  //     validationSchema = yup.object({
  //       identityType: yup.string().required("Identity Type Fee is required"),
  //       idcardFront: yup.mixed().required("Id Card Front Page is required"),
  //       idcardBack: yup.mixed().required("Id Card Back Page is required"),
  //       residentialAddress: yup
  //         .string()
  //         .required("Residential Address is required"),
  //     });
  //     initialValues = {
  //       identityType: "Select Type",
  //       idcardFront: null,
  //       idcardBack: null,
  //       residentialAddress: "",
  //     };
  //   }
  // };

  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        if (field === "idcardFront") {
          setIsUploading(true);
        } else if (field === "idcardBack") {
          setIsUploadingBack(true);
        } else if (field === "passportImage") {
          setIsUploadingPassport(true);
        }

        const reader = new FileReader();

        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            if (field === "idcardFront") {
              setImageFront(base64Data);
              setIsUploading(false);
              setUploadProgress(100); // Set progress to 100% on completion
            } else if (field === "idcardBack") {
              setImageBack(base64Data);
              setIsUploadingBack(false);
              setUploadProgressBack(100); // Set progress to 100% on completion
            } else if (field === "passportImage") {
              setPassportImage(base64Data);
              setIsUploadingPassport(false);
              setUploadProgressPassport(100); // Set progress to 100% on completion
            }
          }
        };

        reader.onprogress = (event) => {
          if (event.loaded && event.total) {
            const progress = Math.round((event.loaded / event.total) * 100);
            if (field === "idcardFront") {
              setUploadProgress(progress);
            } else if (field === "idcardBack") {
              setUploadProgressBack(progress);
            } else if (field === "passportImage") {
              setUploadProgressPassport(progress);
            }
          }
        };

        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const validateForm = (values, type) => {
    let errors = {};
    if (!values.country) errors.country = "Country is required";
    if (!values.fullName && type === "Bank") errors.fullName = "Full name is required";
    if (!values.iban && type === "Bank") errors.iban = "IBAN is required";
    if (!values.bic && type === "Bank") errors.bic = "BIC is required";
    if (!values.address) errors.address = "Address is required";
    if (!values.city) errors.city = "City is required";
    if (!values.zipCode) errors.zipCode = "Zip code is required";
    if (!values.pcountry) errors.pcountry = "Payout country is required";

    if (type === "Bank_out_ue") {
      if (!values.bankAccountName) errors.bankAccountName = "Bank account name is required";
      if (!values.bankAccountNumber) errors.bankAccountNumber = "Bank account number is required";
      if (!values.bankAccountSwiftCode) errors.bankAccountSwiftCode = "Swift code is required";
      if (!values.bankAccountRouting) errors.bankAccountRouting = "Routing number is required";
    }

    return errors;
  };


  const handleSubmit = () => {
    setSubload(true)
    const formData = new FormData();
    formData.append("identityType", selectedValue);
    if (selectedValue === "Passport") {
      if (!passportImage) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", passportImage);
      }
    } else if (selectedValue === "Identity Card") {
      if (!imageFront || !imageBack) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", imageFront);
        formData.append("idcardBack", imageBack);
      }
    }
    uploadKycData(
      formData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setImageFront(null);
          setImageBack(null);
        } else if (response?.data?.status === "success") {
          setImageBack(null);
          setImageFront(null);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });

          setloginUserData((prevState) => ({
            ...prevState,
            kyc_status: "Uploaded",
          }));
          setImageFront(null);
          setImageBack(null);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setImageFront(null);
          setImageBack(null);
        }
        setSubload(false)
      },
      (error) => {
        setalertData({
          show: true,
          message: "Something went wrong please try again",
          variant: "error",
        });
        setSelectedValue(" ");
        setImageFront(null);
        setImageBack(null);
        setSubload(false)
      }
    );
  };

  const handleDocumentationType = (e) => {
    setSelectedValue(e.target.value);
  };

  const handlePaymentInformationType = (e) => {
    setPaymentInformationType(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (paymentInformationType === "Bank") {
      setFormValues({
        ...formValues,
        [name]: value,
      });
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    } else if (paymentInformationType === "Bank_out_ue") {
      setFormData({
        ...formData,
        [name]: value,
      });
      setFormDataErrors({
        ...formDataErrors,
        [name]: '',
      });
    }
  };


  const handleSelectChange = (event, newValue) => {
    if (paymentInformationType === "Bank") {
      setFormValues({
        ...formValues,
        country: newValue ? newValue.label : "",
      });
    } else if (paymentInformationType === "Bank_out_ue") {
      setFormData({
        ...formData,
        country: newValue ? newValue.label : "",
      });
    }
  };

  const handleFormSubmit = () => {
    let params = {};
    let errors = {};

    if (paymentInformationType === "Bank") {
      errors = validateForm(formValues, "Bank");
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      params = {
        type: paymentInformationType,
        country: formValues.country,
        bank_account_name: formValues.fullName,
        bank_account_bic: formValues.bic,
        bank_account_iban: formValues.iban,
        bank_account_address: formValues.address,
        bank_account_city: formValues.city,
        bank_account_zip_code: formValues.zipCode,
        payment_country: formValues.pcountry,
      };
    }
    if (paymentInformationType === "Bank_out_ue") {
      errors = validateForm(formData, "Bank_out_ue");
      if (Object.keys(errors).length > 0) {
        setFormDataErrors(errors);
        return;
      }
      params = {
        type: paymentInformationType,
        country: formData.country,
        bank_account_name: formData.bankAccountName,
        bank_account_number: formData.bankAccountNumber,
        bank_account_swift_code: formData.bankAccountSwiftCode,
        bank_account_routing: formData.bankAccountRouting,
        bank_account_address: formData.address,
        bank_account_city: formData.city,
        bank_account_zip_code: formData.zipCode,
        payment_country: formData.pcountry,
      };
    }

    UpdatePayoutDetailsApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setFormValues({
            country: "",
            fullName: "",
            iban: "",
            bic: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
          setFormData({
            country: "",
            bankAccountName: "",
            bankAccountNumber: "",
            bankAccountSwiftCode: "",
            bankAccountRouting: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
        } else if (response?.data?.status === "success") {
          setLoading(true)
          setMessage('');
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });

          if (paymentInformationType === "Bank_out_ue") {
            setloginUserData((prevState) => ({
              ...prevState,
              outside_bank_account_country: formData.country,
              outside_bank_account_title: formData.bankAccountName,
              outside_bank_account_number: formData.bankAccountNumber,
              outside_bank_account_swift_code: formData.bankAccountSwiftCode,
              outside_bank_account_routing: formData.bankAccountRouting,
              outside_bank_account_address: formData.address,
              outside_bank_account_city: formData.city,
              outside_bank_account_zip_code: formData.zipCode,
              outside_payout_country: formData.pcountry,
              porequestcount: 1,
            }));
          }
          if (paymentInformationType === "Bank") {
            setloginUserData((prevState) => ({
              ...prevState,
              bank_account_country: formValues.country,
              bank_account_title: formValues.fullName,
              bank_account_bic: formValues.bic,
              bank_account_iban: formValues.iban,
              bank_account_address: formValues.address,
              bank_account_city: formValues.city,
              bank_account_zip_code: formValues.zipCode,
              payout_country: formValues.pcountry,
              porequestcount: 1,
            }));
          }
          setFormValues({
            country: "",
            fullName: "",
            iban: "",
            bic: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
          setFormData({
            country: "",
            bankAccountName: "",
            bankAccountNumber: "",
            bankAccountSwiftCode: "",
            bankAccountRouting: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
          setLoading(false)
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setFormValues({
            country: "",
            fullName: "",
            iban: "",
            bic: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
          setFormData({
            country: "",
            bankAccountName: "",
            bankAccountNumber: "",
            bankAccountSwiftCode: "",
            bankAccountRouting: "",
            address: "",
            city: "",
            zipCode: "",
            pcountry: "",
          });
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: "Something went wrong please try again",
          variant: "error",
        });
        setFormValues({
          country: "",
          fullName: "",
          iban: "",
          bic: "",
          address: "",
          city: "",
          zipCode: "",
          pcountry: "",
        });
        setFormData({
          country: "",
          bankAccountName: "",
          bankAccountNumber: "",
          bankAccountSwiftCode: "",
          bankAccountRouting: "",
          address: "",
          city: "",
          zipCode: "",
          pcountry: "",
        });
      }
    );
  };

  // const handleClick = () => {
  //   handleSubmit();
  //   handleFormSubmit();
  // };

  const handleAgreement = () => {
    window.open(
      "https://novalya.com/wp-content/uploads/2024/02/Contrat-Affiliation-Novalya-01-Fev-2024-.pdf",
      "_blank"
    );
  };

  const handleLink = () => {
    const url = "https://novalya.com/affiliate-compensation/";
    window.open(url, "_blank");
  };


  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMessage = () => {
    setMessage('update payout information');
    handleClose();
    // Additional logic if needed
  };

  return (
    <AffiliateLayout>
      <div
        style={{
          display: "grid",
          gap: "20px",
          gridTemplateColumns: "2.8fr 1.2fr",
        }}
      >
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
        <Card sx={{ padding: "20px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 4fr",
              gap: "20px",
            }}
          >
            <span
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  display: "block",
                  textAlign: "left",
                  width: "160px",
                  marginLeft: "7px"
                }}
                htmlFor="kycStatus"
              >
                KYC Status
              </label>
              <Button
                sx={{
                  backgroundColor: loginUserData?.kyc_status === 'Unverified' && loginUserData?.kycRejectcount?.length === 0 ? '#A0A0A0' : loginUserData?.kyc_status === 'Uploaded' ? '#FCD431' : '',
                  '&:hover': {
                    backgroundColor: loginUserData?.kyc_status === 'Unverified' && loginUserData?.kycRejectcount?.length === 0 ? '#808080' : loginUserData?.kyc_status === 'Uploaded' ? '#EAE86F' : '',
                  },
                  color: loginUserData?.kyc_status === 'Uploaded' ? '#5C5C5C' : '',
                }}
                variant="contained"
                color={loginUserData?.kycRejectcount?.length > 0 && loginUserData?.kyc_status === 'Unverified' ? 'error' : 'success'}>
                {loginUserData?.kyc_status === 'Uploaded' ? 'Under Review' : loginUserData?.kycRejectcount?.length > 0 && loginUserData?.kyc_status === 'Unverified' ? 'Rejected' : loginUserData?.kyc_status === 'Unverified' ? 'Not Verified' : loginUserData?.kyc_status}
              </Button>

            </span>
            <span
              style={{
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  display: "block",
                  textAlign: "left",
                  width: "160px"
                }}
                htmlFor="bankReviewStatus"
              >
                Bank Review Status
              </label>
              <Button
                sx={{
                  backgroundColor: !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title && loginUserData?.poRejectedCount?.length === 0 ? '#A0A0A0' : loginUserData?.porequestcount === 1 ? '#FCD431' : '',
                  '&:hover': {
                    backgroundColor: !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title ? '#808080' : loginUserData?.porequestcount === 1 ? '#EAE86F' : '',
                  },
                  color: loginUserData?.porequestcount === 1 ? '#5C5C5C' : '',
                }}
                variant="contained"
                color={loginUserData?.poRejectedCount?.length > 0 && !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title ? 'error' : 'success'}>
                {
                  loginUserData?.porequestcount === 1 ? 'Under Review'
                    : !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title && loginUserData?.poRejectedCount?.length > 0 ? 'Rejected'
                      : !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title ? 'Not Set Up' : 'Approved'
                }

              </Button>
            </span>

          </div>

          {
            loginUserData?.kycRejectcount?.length > 0 && loginUserData?.kyc_status === 'Unverified' ?
              <Alert variant={'filled'} severity="error" sx={{ marginTop: "15px" }}>
                KYC Request Rejected: {loginUserData?.kycRejectcount[0]?.reason}
              </Alert> : null
          }

          {
            loginUserData?.poRejectedCount?.length > 0 && !loginUserData?.bank_account_title && !loginUserData?.outside_bank_account_title ?
              <Alert variant={'filled'} severity="error" sx={{ marginTop: "15px" }}>
                Bank Request Rejected: {loginUserData?.poRejectedCount[0]?.reason}
              </Alert> : null
          }

          {loginUserData?.kyc_status === "Unverified" && (
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  fontFamily: "'DM Sans'",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                Start Verification Document
              </div>
              <div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="id_card"
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      alignItems: "center",
                      gap: "30px",
                    }}
                    onChange={handleDocumentationType}
                  >
                    <FormControlLabel
                      sx={{
                        border: "1px solid rgba(94, 59, 183, 0.35)",
                        margin: "0",
                        padding: "10px",
                        borderRadius: "12px",
                        background: "rgba(94, 59, 183, 0.07)",
                        color: "rgba(99, 115, 129, 1)",
                        fontFamily: "'DM Sans'",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "21.6px",
                        textAlign: "left",
                        width: "152px",
                        height: "40px",
                      }}
                      value="Identity Card"
                      control={<Radio />}
                      label="Id card"
                      checked={selectedValue === 'Identity Card'}
                    />
                    <span
                      style={{
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "18.23px",
                        textAlign: "left",
                      }}
                    >
                      OR
                    </span>
                    <FormControlLabel
                      sx={{
                        border: "1px solid rgba(94, 59, 183, 0.35)",
                        margin: "0",
                        padding: "10px",
                        borderRadius: "12px",
                        background: "rgba(94, 59, 183, 0.07)",
                        color: "rgba(99, 115, 129, 1)",
                        fontFamily: "'DM Sans'",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "21.6px",
                        textAlign: "left",
                        width: "152px",
                        height: "40px",
                      }}
                      value="Passport"
                      control={<Radio />}
                      label="Passport"
                      checked={selectedValue === 'Passport'}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {selectedValue === "Identity Card" && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "40px",
                    marginTop: "15px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Select front image
                    </div>
                    <label
                      style={{
                        border: "3px dashed rgba(58, 124, 160, 1)",
                        background: "rgba(241, 250, 255, 1)",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        textAlign: "center",
                        gap: "15px",
                        cursor: 'pointer'
                      }}
                      htmlFor="aadhar-front-side"
                    >
                      <BsUpload />
                      <span>
                        Drag and Drop files here or <b>Choose File</b>
                      </span>
                    </label>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="aadhar-front-side"
                      onChange={(event) => {
                        handleUpload(event, "idcardFront");
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "15.62px",
                        textAlign: "left",
                      }}
                    >
                      <span>Supported Format: JPEG, PNG, PDF</span>
                      <span>Maximum size: 5MB</span>
                    </div>
                    {isUploading && (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#f3f3f3",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: `${uploadProgress}%`,
                            height: "10px",
                            backgroundColor: "#4caf50",
                            borderRadius: "4px",
                            textAlign: "center",
                            color: "white",
                            lineHeight: "10px",
                          }}
                        >
                          {uploadProgress}%
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "center",
                        border: "1px solid rgba(94, 59, 183, 0.15)",
                        borderRadius: "12px",
                        maxWidth: "180px",
                        margin: "15px 0px",
                        padding: "10px",
                      }}
                    >
                      <span>Front</span>
                      <img
                        src={imageFront || "/images/aadhar-front-side.svg"}
                        alt=""
                        style={{ width: "162px", height: "90px" }}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Select back image
                    </div>
                    <label
                      style={{
                        border: "3px dashed rgba(58, 124, 160, 1)",
                        background: "rgba(241, 250, 255, 1)",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        textAlign: "center",
                        gap: "15px",
                        cursor: 'pointer'
                      }}
                      htmlFor="aadhar-back-side"
                    >
                      <BsUpload />
                      <span>
                        Drag and Drop files here or <b>Choose File</b>
                      </span>
                    </label>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="aadhar-back-side"
                      onChange={(event) => {
                        handleUpload(event, "idcardBack");
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "15.62px",
                        textAlign: "left",
                      }}
                    >
                      <span>Supported Format: JPEG, PNG, PDF</span>
                      <span>Maximum size: 5MB</span>
                    </div>
                    {isUploadingBack && (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#f3f3f3",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: `${uploadProgressBack}%`,
                            height: "10px",
                            backgroundColor: "#4caf50",
                            borderRadius: "4px",
                            textAlign: "center",
                            color: "white",
                            lineHeight: "10px",
                          }}
                        >
                          {uploadProgressBack}%
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "center",
                        border: "1px solid rgba(94, 59, 183, 0.15)",
                        borderRadius: "12px",
                        maxWidth: "180px",
                        margin: "15px 0px",
                        padding: "10px",
                      }}
                    >
                      <span>Back</span>
                      <img
                        src={imageBack || "/images/aadhar-back-side.svg"}
                        alt=""
                        style={{ width: "162px", height: "90px" }}
                      />
                    </div>
                  </div>

                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}

                    disabled={subload}

                  >
                    <span>Submit</span>
                    <GoArrowRight />
                  </Button>
                </div>
              )}
              {selectedValue === "Passport" && (
                <div style={{ marginTop: "15px" }}>
                  <div>
                    <div
                      style={{
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "left",
                        marginBottom: "10px",
                      }}
                    >
                      Select Only front image
                    </div>
                    <label
                      style={{
                        border: "3px dashed rgba(58, 124, 160, 1)",
                        background: "rgba(241, 250, 255, 1)",
                        height: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "12px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        textAlign: "center",
                        gap: "15px",
                        cursor: 'pointer'
                      }}
                      htmlFor="passport-pic"
                    >
                      <BsUpload />
                      <span>Drag and Drop files here or Choose File</span>
                    </label>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="passport-pic"
                      onChange={(event) => {
                        handleUpload(event, "passportImage");
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontFamily: "'DM Sans'",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "15.62px",
                        textAlign: "left",
                      }}
                    >
                      <span>Supported Format: JPEG, PNG, PDF</span>
                      <span>Maximum size: 5MB</span>
                    </div>
                    {isUploadingPassport && (
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "#f3f3f3",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            width: `${uploadProgressPassport}%`,
                            height: "10px",
                            backgroundColor: "#4caf50",
                            borderRadius: "4px",
                            textAlign: "center",
                            color: "white",
                            lineHeight: "10px",
                          }}
                        >
                          {uploadProgressPassport}%
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "'DM Sans'",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.23px",
                        textAlign: "center",
                        border: "1px solid rgba(94, 59, 183, 0.15)",
                        borderRadius: "12px",
                        maxWidth: "180px",
                        margin: "15px 0px",
                        padding: "10px",
                      }}
                    >
                      <span>Front</span>
                      <img
                        src={passportImage || "/images/passport-front.svg"}
                        alt=""
                        style={{ width: "162px", height: "90px" }}
                      />
                    </div>
                  </div>
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                    disabled={subload}
                  >
                    <span>Submit</span>
                    <GoArrowRight />
                  </Button>
                </div>
              )}
            </div>
          )}
          {loginUserData?.kyc_status === "Uploaded" && (
            <Alert severity="info" sx={{ marginTop: "15px" }}>
              You have successfully uploaded your documents. It will be verify soon.
              Thank You!.{" "}
            </Alert>
          )}

          {
            loginUserData?.kyc_status !== 'Verified' ?
              <Alert severity="info" sx={{ marginTop: "15px" }}>
                Please verify your KYC to set up your payment method for payouts.{" "}
              </Alert> : null
          }

          <div>

            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  fontFamily: "'DM Sans'",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  textAlign: "left",
                  marginBottom: "10px",
                }}
              >
                Payment Information
              </div>
              {(loginUserData?.bank_account_title === null &&
                loginUserData?.outside_bank_account_title === null &&
                loginUserData?.porequestcount === 0) || message === 'update payout information' ? (
                <div>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="europe"
                      name="payment_info"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                        gap: "30px",
                      }}
                      onChange={handlePaymentInformationType}
                    >
                      <FormControlLabel
                        sx={{
                          border: "1px solid rgba(94, 59, 183, 0.35)",
                          margin: "0",
                          padding: "10px",
                          borderRadius: "12px",
                          background: "rgba(94, 59, 183, 0.07)",
                          color: "rgba(99, 115, 129, 1)",
                          fontFamily: "'DM Sans'",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "21.6px",
                          textAlign: "left",
                          width: "152px",
                          height: "40px",
                        }}
                        value="Bank"
                        control={<Radio />}
                        label="Europe"
                        checked={paymentInformationType === 'Bank'}
                      />
                      <FormControlLabel
                        sx={{
                          border: "1px solid rgba(94, 59, 183, 0.35)",
                          margin: "0",
                          padding: "10px",
                          borderRadius: "12px",
                          background: "rgba(94, 59, 183, 0.07)",
                          color: "rgba(99, 115, 129, 1)",
                          fontFamily: "'DM Sans'",
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "21.6px",
                          textAlign: "left",
                          width: "152px",
                          height: "40px",
                        }}
                        value="Bank_out_ue"
                        control={<Radio />}
                        label="Outside EU"
                        checked={paymentInformationType === 'Bank_out_ue'}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : loginUserData?.porequestcount > 0 ? (
                <Alert severity="info" sx={{ marginTop: "15px" }}>
                  Your request has been submitted to admin, it will be verify soon.{" "}
                </Alert>
              ) : (
                <>

                  {loginUserData?.wallet_address === null && loginUserData?.outside_bank_account_title === null && message === '' ? (
                    <>
                      <Grid container>
                        <Grid item md={6} xs={12}>
                          <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_country') + ': ' + loginUserData?.bank_account_country} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_full_name') + ': ' + loginUserData?.bank_account_title} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceWalletIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_iban') + ': ' + loginUserData?.bank_account_iban?.toUpperCase()} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AdminPanelSettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_bic') + ': ' + loginUserData?.bank_account_bic?.toUpperCase()} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.p_address') + ': ' + loginUserData?.bank_account_address} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <LocationCityIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_city') + ': ' + loginUserData?.bank_account_city} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <FolderZipIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_zip_code') + ': ' + loginUserData?.bank_account_zip_code} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.p_country') + ': ' + loginUserData?.payout_country} />
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          textTransform: "capitalize",
                          ml: 3,
                          mt: 3
                        }}
                        variant="contained"
                        color="error"
                        onClick={handleClickOpen}
                      >
                        <span>Update Payout Method</span>
                        <GoArrowRight />
                      </Button>
                    </>
                  ) : loginUserData?.wallet_address === null && loginUserData?.bank_account_title === null && message === '' ? (
                    <>
                      <Grid container>
                        <Grid item md={6} xs={12}>
                          <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_country') + ': ' + loginUserData?.outside_bank_account_country} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_full_name') + ': ' + loginUserData?.outside_bank_account_title} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceWalletIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_number') + ': ' + loginUserData?.outside_bank_account_number} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <CodeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_swift_code') + ': ' + loginUserData?.outside_bank_account_swift_code} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AltRouteIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_routing') + ': ' + loginUserData?.outside_bank_account_routing} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.p_address') + ': ' + loginUserData?.outside_bank_account_address} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <LocationCityIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_city') + ': ' + loginUserData?.outside_bank_account_city} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <FolderZipIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.ba_zip_code') + ': ' + loginUserData?.outside_bank_account_zip_code} />
                          </ListItem>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(0, 3) }}>
                            <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                              <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('pages.title.p_country') + ': ' + loginUserData?.outside_payout_country} />
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          textTransform: "capitalize",
                          ml: 3,
                          mt: 3
                        }}
                        variant="contained"
                        color="error"
                        onClick={handleClickOpen}
                      >
                        <span>Update Payout Method</span>
                        <GoArrowRight />
                      </Button>
                    </>
                  ) : null}
                </>
              )}
            </div>
            {(loginUserData?.bank_account_title === null &&
              loginUserData?.wallet_address === null &&
              loginUserData?.outside_bank_account_title === null &&
              loginUserData?.porequestcount === 0) || message === 'update payout information' ? (
              <>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  {/* <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={20}
                onChange={handleChange}
                label="Select Payment Option"
              >
                <MenuItem value={'Bank'}>Bank Wire with IBAN (Europe)</MenuItem>
                <MenuItem value={'Bank_out_ue'}>Bank Wire (outside UE)</MenuItem>
              </Select>
            </FormControl> */}
                  {paymentInformationType === "Bank" && (
                    <Alert severity="info" sx={{ marginTop: "15px" }}>
                      Payment are made in Euro and EUR 5 will be deduct as fee of
                      the process. Receiver bank may take some fees.{" "}
                    </Alert>
                  )}

                  {paymentInformationType === "Bank_out_ue" && (
                    <Alert severity="info" sx={{ marginTop: "15px" }}>
                      Payment are made in USD and USD 5 will be deduct as fee of
                      the process. Receiver bank may take some fees.{" "}
                    </Alert>
                  )}
                </div>
                <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                  <div
                    style={{
                      fontFamily: "'DM Sans'",
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "23.44px",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Bank Details
                  </div>
                  {paymentInformationType === "Bank" && (
                    <div style={{ marginTop: "15px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px" }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          onChange={(event, newValue) => {
                            handleSelectChange(event, newValue);
                            setSelectedCountry(newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              name="country"
                              fullWidth
                              {...params}
                              label={t("pages.title.ba_country")}
                              inputProps={{ ...params.inputProps }}
                              error={!!formErrors.country}
                              helperText={formErrors.country}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: selectedCountry ? (
                                  <img
                                    loading="lazy"
                                    style={{ width: 25, marginRight: 8 }}
                                    src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                ) : null,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <TextField
                        id="outlined-basic"
                        label="Your Full Name"
                        variant="outlined"
                        name="fullName"
                        value={formValues.fullName}
                        onChange={handleChange}
                        error={!!formErrors.fullName}
                        helperText={formErrors.fullName}
                        sx={{ height: '30px' }}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Account IBAN"
                        variant="outlined"
                        name="iban"
                        value={formValues.iban}
                        onChange={handleChange}
                        error={!!formErrors.iban}
                        helperText={formErrors.iban}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Account BIC"
                        variant="outlined"
                        name="bic"
                        value={formValues.bic}
                        onChange={handleChange}
                        error={!!formErrors.bic}
                        helperText={formErrors.bic}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Personal Address"
                        variant="outlined"
                        name="address"
                        value={formValues.address}
                        onChange={handleChange}
                        error={!!formErrors.address}
                        helperText={formErrors.address}
                      />
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        name="city"
                        value={formValues.city}
                        onChange={handleChange}
                        error={!!formErrors.city}
                        helperText={formErrors.city}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Zip Code"
                        variant="outlined"
                        name="zipCode"
                        value={formValues.zipCode}
                        onChange={handleChange}
                        error={!!formErrors.zipCode}
                        helperText={formErrors.zipCode}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        name="pcountry"
                        value={formValues.pcountry}
                        onChange={handleChange}
                        error={!!formErrors.pcountry}
                        helperText={formErrors.pcountry}
                      />
                    </div>
                  )}

                  {paymentInformationType === "Bank_out_ue" && (
                    <div style={{ marginTop: "15px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "30px" }}>
                      <FormControl fullWidth>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          onChange={(event, newValue) => {
                            handleSelectChange(event, newValue);
                            setSelectedCountry(newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              name="country"
                              fullWidth
                              {...params}
                              label={t("pages.title.ba_country")}
                              inputProps={{ ...params.inputProps }}
                              error={!!formErrors.country}
                              helperText={formErrors.country}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: selectedCountry ? (
                                  <img
                                    loading="lazy"
                                    style={{ width: 25, marginRight: 8 }}
                                    src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                ) : null,
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      <TextField
                        id="outlined-basic"
                        label="Your Full Name"
                        variant="outlined"
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleChange}
                        error={!!formDataErrors.bankAccountName}
                        helperText={formDataErrors.bankAccountName}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Account Number"
                        variant="outlined"
                        name="bankAccountNumber"
                        value={formData.bankAccountNumber}
                        onChange={handleChange}
                        error={!!formDataErrors.bankAccountNumber}
                        helperText={formDataErrors.bankAccountNumber}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Account Swift Code"
                        variant="outlined"
                        name="bankAccountSwiftCode"
                        value={formData.bankAccountSwiftCode}
                        onChange={handleChange}
                        error={!!formDataErrors.bankAccountSwiftCode}
                        helperText={formDataErrors.bankAccountSwiftCode}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Bank Account Routing"
                        variant="outlined"
                        name="bankAccountRouting"
                        value={formData.bankAccountRouting}
                        onChange={handleChange}
                        error={!!formDataErrors.bankAccountRouting}
                        helperText={formDataErrors.bankAccountRouting}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Personal Address"
                        variant="outlined"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        error={!!formDataErrors.address}
                        helperText={formDataErrors.address}
                      />
                      <TextField
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        error={!!formDataErrors.city}
                        helperText={formDataErrors.city}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Zip Code"
                        variant="outlined"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleChange}
                        error={!!formDataErrors.zipCode}
                        helperText={formDataErrors.zipCode}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        name="pcountry"
                        value={formData.pcountry}
                        onChange={handleChange}
                        error={!!formDataErrors.pcountry}
                        helperText={formDataErrors.pcountry}
                      />
                    </div>
                  )}

                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  {/* <Button
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
              variant="contained"
              color="primary"
            >
              <FaPencil />
              <span>Edit</span>
            </Button> */}
                  <Button
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                    color="success"
                    onClick={handleFormSubmit}
                  >
                    <span>Submit</span>
                    <GoArrowRight />
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </Card>
        <div>
          <div
            className="novalya-dashboard-warp-right"
            style={{ marginTop: "auto" }}
          >
            <div
              className="novalya-dashboard-right"
              style={{
                backgroundImage:
                  "url(/images/affiliate-dashboard-button-bg.svg)",
                backgroundPosition: "bottom right",
                backgroundRepeat: "no-repeat",
                backgroundColor: "rgba(44, 115, 255, 0.1)",
                height: "318px"
              }}
            >
              <div className="novalya-dashboard-rightactive">
                <div
                  style={{
                    fontFamily: "'DM Sans'",
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "23.44px",
                    textAlign: "center",
                    paddingTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  Documents
                </div>
                <div className="novalya-rightactive-text" style={{ textAlign: "center" }} >
                  <button
                    className="nova-prospecting"
                    style={{
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "23.44px",
                      letterSpacing: "0.34px",
                      textTransform: "none",
                      outline: "none",
                      margin: "auto",
                      width: "80%",
                      marginTop: "35px",
                      background: "rgba(44, 115, 255, 1)",
                      color: "#fff",
                      border: "1px solid rgba(44, 115, 255, 0.5)",
                      maxWidth:"254px",
                      display:"block !important"
                    }}
                    onClick={() => handleAgreement()}
                  >
                    {t("Affiliate Agreement")}
                  </button>
                </div>
                <div className="novalya-rightactive-text" style={{ textAlign: "center" }}>
                  <button
                    className="nova-prospecting"
                    style={{
                      fontFamily: "'DM Sans'",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "23.44px",
                      letterSpacing: "0.34px",
                      textTransform: "none",
                      outline: "none",
                      margin: "auto",
                      width: "80%",
                      marginTop: "35px",
                      background: "#fff",
                      color: "rgba(44, 115, 255, 1)",
                      border: "1px solid rgba(44, 115, 255, 0.5)",
                      maxWidth:"254px",
                      display:"block !important"
                    }}
                    onClick={() => handleLink()}
                  >
                    {t("Compensation Plan")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Update Payout Method"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'red' }} >
            Are you sure you want to update the payment info? This action will not change the current process of an existing payment.
            Thank you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" onClick={handleMessage}>Yes</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </AffiliateLayout>
  );
}
