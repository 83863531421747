import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import { fetchUnfollowList, saveWhitelist } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SyncIcon from "@mui/icons-material/Sync";
import CloseIcon from "@mui/icons-material/Close";
import "./Table.css";
import { Datatable2 } from "app/pages/components/mui/Datatable";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { novaButtonsConfig } from "./TabData";
import { styled } from "@mui/system";
import { useModalState } from "app/hooks/use-modal-state";

import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";
const FreindsList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [pagination, setPagination] = useState({ pageSize: 25, page: 1 });
  const [totalPages, setTotalPages] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedRows, setSelectedRows] = React.useState([]);
  const addSync = useModalState();
  const unfrinedUser = useModalState();
  const whitelistModal = useModalState();
  let params = {};
  const FetchGroupData = () => {
    setDataLoading(true);

    fetchUnfollowList(
      params,
      (response) => {
        setLoading(false);
        setDataLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
        setTotalPages(response?.data?.totalPages);
        setCurrentPage(response?.data?.currentPage);
        setTotalRows(response?.data?.totalCount);
      },
      (error) => {
        setDataLoading(false);
        setLoading(false);
      },
      pagination
    );
  };
  const { loginUserData } = useContext(CustomProvider);
  const onWhitelist = () => {
    let params = {
      ids: selectedRows,
    };
    if (params.ids.length == 0) {
      setalertData({
        show: true,
        message: t("pages.title.Please Select friend"),
        variant: "error",
      });
    } else {
      saveWhitelist(
        params,
        (response) => {
          if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: "Successfully Added to Whitelist",
              variant: "success",
            });
            FetchGroupData();
            whitelistModal.onClose();
          }
        },
        (error) => {
          setalertData({
            show: true,
            message: error?.response?.data?.message,
            variant: "error",
          });
        }
      );
    }
  };

  useEffect(() => {
    setCurrentPage(pagination.page);
    FetchGroupData();
  }, [pagination]);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const columns = [
    {
      field: "user_name",
      headerName: t("pages.title.Member"),
      dataGeneratorUniquenessEnabled: true,
      headerClassName: "nova-name-header",
      width: 250,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          className="nova-name-custom-div"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #E0E1E3",
            color: "#170F49",
            padding: "4px 5px",
            borderRadius: "30px",
          }}
        >
          <img
            src={params.row.image}
            alt={params.row.user_name}
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
          <span className="nova-name-custom-span">{params.row.user_name}</span>
          <IconButton
            className="nova-fb-icon"
            target="_blank"
            href={params.row.profile}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="17.5"
                fill="#2C73FF"
                fillOpacity="0.1"
                stroke="#E0E1E3"
              />
              <path
                d="M19.6667 19.075H22.0476L23 14.875H19.6667V12.775C19.6667 11.6935 19.6667 10.675 21.5714 10.675H23V7.147C22.6895 7.10185 21.5171 7 20.279 7C17.6933 7 15.8571 8.73985 15.8571 11.935V14.875H13V19.075H15.8571V28H19.6667V19.075Z"
                fill="#2C73FF"
              />
            </svg>
          </IconButton>
        </div>
      ),
    },
    {
      field: "mutual_friend",
      headerName: t("pages.title.# Mutual Friends"),
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      headerClassName: "nova-mutual-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          {params.row.mutual_friend ? params.row.mutual_friend : 0}
        </div>
      ),
    },

    {
      field: "gender",
      headerName: t("pages.title.Gender"),
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      headerClassName: "nova-gender-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-gender-custom-div">
          {params.row.gender
            ? params.row.gender == "Male"
              ? t("pages.title.male")
              : t("pages.title.female")
            : "-"}
        </div>
      ),
    },
    {
      field: "lived",
      headerName: t("pages.title.Lives In"),
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          { params.row.lived?.trim() || "-"}
        </div>
      ),
    },
    {
      field: "Bio",
      headerName: "Bio",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          {/* {params.row?.bio?.trim() || "-"} */}
          {"-"}

        </div>
      ),
      sortComparator: (v1, v2, param1, param2) => {
        // Reverse sorting by row index or any other field
        return param1.id < param2.id ? 1 : -1;
      }
    },
    {
      field: "email",
      headerName:"Email",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          {/* {params.row.email?.trim() || "-"} */}
          {"-"}
        </div>
      ),
    },
    {
      field: "contact",
      headerName:"Phone #",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          {/* {params.row?.contact?.trim() || "-"} */}
          {"-"}
        </div>
      ),
    },
    {
      field: "instagram",
      headerName: "Social",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
         {/* <div>
          {params.row?.linkedIn?.trim()}
         </div>
         <div>
          { params.row?.instagram?.trim()}
         </div>
         <div>
          {params.row?.youTube?.trim()}
         </div>
            {params.row?.linkedIn?.trim()+params.row?.instagram?.trim()+params.row?.youTube?.trim()?"":"-"} */}
            {"-"}
        </div>
      ),
      
    },
    {
      field: "website",
      headerName: "Website",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      headerClassName: "nova-lives-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          { "-"}
          
        </div>
      ),
      sortComparator: (v1, v2, param1, param2) => {
        // Reverse sorting by row index or any other field
        return param1.id < param2.id ? 1 : -1;
      }
    },
  ];

  return (
    <>
      <TopHeader
        title={t("pages.title.List of Friends")}
        url={t("pages.title.friend_video_url")}
      />
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <TabMenu buttonsConfig={novaButtonsConfig} />
      <div className="nova-container">
        <div className="nova-box nova-box-only nova--friends">
          <Box className="nova-btn-card btn-group-friends-list">
            <Button
              className="nova-red-btn"
              variant="outlined"
              onClick={() => unfrinedUser.onOpen()}
              endIcon={<CloseIcon />}
              sx={{
                backgroundColor: "#FF0202",
                "&:hover": { backgroundColor: "#E40505" },
              }}
            >
              {t("pages.title.unfriended")}
            </Button>
            <Button
              className="nova-green-btn"
              variant="contained"
              onClick={() => whitelistModal.onOpen()}
              sx={{
                backgroundColor: "#41CE81",
                "&:hover": { backgroundColor: "#34BC72" },
              }}
            >
              {t("pages.title.addwhitelist")}
            </Button>
            <Button
              className="nova-blue-btn"
              variant="contained"
              startIcon={<SyncIcon />}
              onClick={() => addSync.onOpen()}
            >
              {t("pages.title.asyncnovadata")}
            </Button>
          </Box>

          <Datatable2
            rows={groupData}
            columns={columns}
            loading={dataLoading}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            onPaginationModelChange={(pagedata) =>
              setPagination((data) => ({
                ...data,
                pageSize: pagedata.pageSize,
              }))
            }
            // rowCount={totalRows}
            totalPages={totalPages}
            pageSize={pagination.pageSize}
            currentPage={currentPage}
            onPageTrigger={(pagedata) => {
              if (parseInt(pagedata)) {
                setPagination((data) => ({
                  ...data,
                  page: parseInt(pagedata) || data.page,
                }));
              }
            }}
           
            className="nova-table-ctm"
            // className="nova-table-ctm nova-table-activated"
          />
          {/* <div className="nova-table-activated-btn"><button>Unlock</button></div> */}
        </div>
      </div>
      {/* start sync model  */}
      <BootstrapDialog
        className="nova-warning-dialog"
        open={addSync.isOpen}
        onClose={addSync.onClose}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            Warning
          </Typography>
        </Box>

        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box className="nova-warning-box">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "left" }}>
                This process will take approximatively 4 hours per 1000 friends{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "left" }}>
                Your Computer and Internet connexion should stay ON during the
                full process
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "left" }}>
                DO NOT use any other Novalya's Facebook Features during the
                process. (However you can still answer your direct messages)
              </Typography>
            </Box>
          </Box>
          <Box
            className="stage-btn-group"
            sx={{
              backgroundColor: "#F4F1FA",
              m: "-20px",
              pb: "15px",
              pt: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <button
                variant="outlined"
                className="nova-btn-start green-pop-btn"
                id="async_novadata"
              >
                Start
              </button>
              <button
                className="nova-btn-cancel gray-pop-btn"
                onClick={addSync.onClose}
              >
                {t("pages.title.cancel")}
              </button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>

      {/* start unfriend model   */}
      <BootstrapDialog
        className="novafriend-popups"
        open={unfrinedUser.isOpen}
        onClose={unfrinedUser.onClose}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            {t("pages.title.Unfriend")}
          </Typography>
        </Box>
        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "center" }}>
                {t("pages.title.Are you sure you want to Unfriend")}{" "}
                {selectedRows.length} {t("pages.title.friends")}?
              </Typography>
            </Box>

            <Box
              className="stage-btn-group"
              sx={{
                backgroundColor: "#F4F1FA",
                m: "-20px",
                pb: "15px",
                pt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <button
                  variant="contained"
                  className="green-pop-btn"
                  attr-data={JSON.stringify({
                    userIds: selectedRows,
                  })}
                  id="async_unfriend"
                >
                  {t("pages.title.confirm")}
                </button>
                <button onClick={unfrinedUser.onClose} className="gray-pop-btn">
                  {t("pages.title.cancel")}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      {/* end unfriend model   */}

      {/* start add whitelist  */}
      <BootstrapDialog
        className="novafriend-popups"
        open={whitelistModal.isOpen}
        onClose={whitelistModal.onClose}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            Whitelist
          </Typography>
        </Box>

        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "center" }}>
                Are you sure you want to Whitelist {selectedRows.length}{" "}
                friends?
              </Typography>
            </Box>

            <Box
              className="stage-btn-group"
              sx={{
                backgroundColor: "#F4F1FA",
                m: "-20px",
                pb: "15px",
                pt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <button
                  variant="contained"
                  color="error"
                  className="green-pop-btn"
                  onClick={onWhitelist}
                  id="async_unfriend"
                >
                  Confirm
                </button>
                <button
                  onClick={whitelistModal.onClose}
                  className="gray-pop-btn"
                >
                  Cancel
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      {/* end add whitelist  */}
    </>
  );
};

export default FreindsList;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
