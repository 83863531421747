import React, { useContext } from 'react';
import { Avatar, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';

export default function CustomerTable(props) {
	const { t } = useTranslation();
	const { loginUserData } = useContext(CustomProvider);
	let showStatus = true;

	if (props.hasOwnProperty('showStatus')) {
		showStatus = props.showStatus
	}

	const color = {
		"In trail" : "#2c73ff",
		"Active": "#22C55E",
		"Paid": "#22C55E",
		"To Be Collected": "#FFC804",
		"Payment Due": "#FF7A00",
		"Cancelled": "#FF0000"
	};

	console.log('plan data', props?.dashboardData?.referralNewUserData?.referralUserData)

	const formatDate = (timestamp) => {
		const date = new Date(timestamp * 1000);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	};

	const formatDate1 = (dateInput) => {
		
		let date;
		date = new Date(dateInput); // Handle ISO 8601 format
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	};

	const formatDate2 = (dateInput) =>{
		let date = new Date(dateInput * 1000);
    
		// Define options to get the date in "Month Day, Year" format
		let options = { year: 'numeric', month: 'long', day: 'numeric' };
		
		// Format the date according to the options
		let formattedDate = date.toLocaleDateString('en-US', options);
		
		return formattedDate;
	}

	return (
		<TableContainer component={Paper}>
			<Table className='sep-AffiliateTable-main' sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead >
					<TableRow>
						<TableCell>#</TableCell>
						<TableCell>Name</TableCell>
						<TableCell align="center">Plan</TableCell>
						<TableCell align="center">Period</TableCell>
						<TableCell align="center">Price</TableCell>
						<TableCell align="center">Joining Date</TableCell>
						<TableCell align="center">Activation on</TableCell>
						<TableCell align="center">Sponsored By</TableCell>
						<TableCell align="center">Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props?.dashboardData?.referralNewUserData?.referralUserData?.length === 0 ? (
						<tr>
							<td colSpan="6">
								{t("pages.title.Noanynewsaleincurrentmonth")}
							</td>
						</tr>
					) : (
						<>
							{props?.dashboardData?.referralNewUserData?.referralUserData?.map((userData, index) => (
								<TableRow
									key={userData?.firstname + " " + userData?.lastname}
									sx={{
										'&:last-child td, &:last-child th': { border: 0 },
										backgroundColor: index % 2 === 0 ? '#F6F6F6' : 'inherit' // alternate row color
									}}
								>
									<TableCell align='left'>
										{index + 1}
									</TableCell>
									<TableCell align='left'>
										<span className='sep-avatar-wraper'>
											<Avatar alt="Avatar Image" src={props?.dashboardData?.referralNewUserData?.pictureUrl + userData?.picture} sx={{ width: 40, height: 40 }} />
											<span>	
												<span className='sep-table-username'>{userData?.firstname + " " + userData?.lastname}</span> 
												<span className='sep-table-email'>{userData?.email}</span>
											</span>
										</span>
									</TableCell>
									<TableCell align="center">{userData?.pkg_name}</TableCell>
									<TableCell align="center">
										{userData?.sub_type?.charAt(0).toUpperCase() + userData?.sub_type?.slice(1)}
									</TableCell>
									<TableCell align="center">{userData?.amount + " " + userData?.currency}</TableCell>
									<TableCell align="center">{userData?.createdat && formatDate1(userData.createdat)}</TableCell>
									{userData?.trial_end === 0 ? (
										<TableCell align="center">{userData?.createdat && formatDate1(userData.createdat)}</TableCell>
									) : (
										<TableCell align="center">{userData?.trial_end && formatDate2(userData.trial_end)}</TableCell>
									)}
									<TableCell align="center">{loginUserData?.username}</TableCell>
									
										<TableCell align="center">
										{userData?.trial_status === 'Active' ? (
											<span className='sep-table-tags' style={{
												background: color["In trail"],
											}}>
												{
													'In trial'
												}
											</span>
										) : (
											<span style={{
												background: color[userData?.subscription_status],
												padding: "3px 11px",
												borderRadius: "6px",
												color: "white",
												display: "inline-flex",
												width: "100px",
												justifyContent: "center"
											}}>
												{
													userData?.subscription_status
												}
											</span>
										)}
										</TableCell>
								</TableRow>
							)
							)}
						</>
					)}
					{/* {props.customersInfo.map((row, index) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                backgroundColor: index % 2 === 0 ? '#F6F6F6' : 'inherit' // alternate row color
                            }}
                        >
                            <TableCell align='left' sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>
                                <span style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px"}}>
                                    <Avatar alt="Avatar Image" src={row.image} sx={{ width: 30, height: 30 }} />
                                    {row.name}
                                </span>
                            </TableCell>
                            <TableCell align="left" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.email}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.plan}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.price}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.joiningDate}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.nextPayment}</TableCell>
                            <TableCell align="center" sx={{color: "#919EAB", fontSize: "14px", lineHeight: "18px", fontFamily: "'DM Sans'", fontWeight: "500"}}>{row.sponsoredBy}</TableCell>
                            {
                                showStatus &&
                                <TableCell align="center">
                                    <span style={{
                                        background: color[row.status],
                                        padding: "3px 11px",
                                        borderRadius: "6px",
                                        color: "#fff",
                                        display: "inline-flex",
                                        width: "140px",
                                        justifyContent: "center"
                                    }}>{row.status}</span>
                                </TableCell>
                            }
                        </TableRow>
                    ))} */}
				</TableBody>
			</Table>
		</TableContainer>
	);
}